import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Tabs,
  Tab,
  Button,
} from "react-bootstrap";
import { Link, navigate } from "gatsby";
import UpworkCard from "../components/Mobileapp/Upworkcard";
import allData from "../allData";
import Newgamedev from "../components/Gamedevelopment/newgamedev";
import allApiData from "../api";
import PortfolioCom from "../components/Home/PortfolioCom";
import Othersevice from "../components/Mobileapp/Othersevice";
import Faq from "../components/Mobileapp/Faq";
import { getHomeApi } from "../api";
import { IoCallSharp, IoMailSharp } from "react-icons/io5";
import { gameContact } from "../api";
import Slider from "react-slick";
import Seo from "../components/Seo";
import SeoheadData from "../siteScriptData";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../assets/form_loader.gif";

const OtherGameData = allData.otherServiceData("game");
const gameLandingFaqData = allData.gameLandingFaqData;

function Gamelanding() {
  const tabName = ["Frontend","Backend","Database","Infrastructure"];
  // const tabName = ["Design","Frontend","Backend","Database","Infrastructure"];
  const [comp, setComp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [testimonials, setTestmonials] = useState([]);
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    pre_mobile: "",
    description: "",
    country: "",
  });
  const onchange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const onFocus = (e) => {
    const fieldName = e.target.name;
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const onBlur = (e) => {
    const fieldName = e.target.name;
    if (e.target.value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `Enter a value for this field.`,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    }
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    allApiData.getTestimonials().then((data) => {
      let filterTesti = data.data.data.filter(
        (_data) => _data.industry === "Game"
      );
      setTestmonials(filterTesti);
    });
  }, []);

  const [footerAddress, setFooterAddress] = useState({});

  useEffect(() => {
    getHomeApi().then((response) => {
      setFooterAddress(response.footer_settings);
    });
  }, []);

  let handleClick = () => {
    navigate("/game-landing");
  };

  useEffect(() => {
    allApiData.allGamePortfolios().then((data) => {
      let filterData = data.data.filter((_data) => {
        return (
          _data.app_type === "Game Development" && _data.game_landing === true
        );
      });
      setKey(filterData[0]?.tag);
      setComp(filterData);

      // setKey(data.data[0].tag)
      // setComp(data.data);
    });
  }, []);

  useEffect(() => {
    allApiData.aosinit();
  }, []);

  // useEffect(() => {
  //     try {
  //         var f = document.createElement("iframe");
  //         f.src = 'https://forms.zohopublic.in/vasundharainfotech/form/Landingpageform/formperma/fpZNc_jpxJ1iDX86wOWO1jFN5GbJ8mctI0yfhAVRpFE?zf_rszfm=1';
  //         f.style.border = "none";
  //         f.style.height = "100%";
  //         f.style.width = "100%";
  //         f.style.transition = "all 0.5s ease";
  //         var d = document.getElementById("zf_div_fpZNc_jpxJ1iDX86wOWO1jFN5GbJ8mctI0yfhAVRpFE");
  //         d.appendChild(f);
  //         window.addEventListener('message', function (event) {
  //             var evntData = event.data;
  //             if (evntData && evntData.constructor == String) {
  //                 var zf_ifrm_data = evntData.split("|");
  //                 if (zf_ifrm_data.length == 2) {
  //                     var zf_perma = zf_ifrm_data[0];
  //                     var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
  //                     var iframe = document.getElementById("zf_div_fpZNc_jpxJ1iDX86wOWO1jFN5GbJ8mctI0yfhAVRpFE").getElementsByTagName("iframe")[0];
  //                     if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
  //                         var prevIframeHeight = iframe.style.height;
  //                         if (prevIframeHeight != zf_ifrm_ht_nw) {
  //                             iframe.style.height = zf_ifrm_ht_nw;
  //                         }
  //                     }
  //                 }
  //             }
  //         }, false);
  //     } catch (e) { }
  // }, [])

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.first_name?.length > 191) {
      errors.first_name = "FirstName cannot exceed 191 characters ";
    } else if (inputValues.first_name?.trim() === "") {
      errors.first_name = "Enter a value for this field.";
    }

    if (inputValues.last_name?.length > 191) {
      errors.last_name = "lastName cannot exceed 191 characters ";
    } else if (inputValues.last_name?.trim() === "") {
      errors.last_name = "Enter a value for this field.";
    }

    if (inputValues.mobile?.trim() === "") {
      errors.mobile = "Enter a number for this field.";
    }
    if (inputValues.country?.trim() === "") {
      errors.country = "Select a choice.";
    }

    // if (inputValues.country?.trim() === "") {
    //     errors.country = "Select Country "
    // }
    if (inputValues.description?.length > 500) {
      errors.description = "description cannot exceed 500 characters.";
    }

    if (!inputValues.email) {
      errors.email = "Enter a value for this field.";
    }
    if (inputValues.email) {
      const email_format = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!email_format.test(inputValues.email)) {
        errors.email = "Enter a valid email address. (eg: yourname@domain.com)";
      }
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault(); // Prevent form submission
      setLoading(true);
      const errors = validateValues(inputValues);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        console.log(inputValues, "inputValues11111");
        const res = await gameContact(inputValues);
        console.log(res, "res111111");
        setInputValues({
          first_name: "",
          last_name: "",
          email: "",
          pre_mobile: "",
          description: "",
          country: "",
        }); // Reset input values
        if (res.success === true) {
          toast.success(res.message);
          console.log(res.success, "ressssssssssssssssss");
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePhoneNumberChange = (phoneNumber, country) => {
    // console.log(country, "country");
    // setFullNumber(phoneNumber);
    const countryCode = country.dialCode;

    let number = countryCode?.length;
    setInputValues({
      ...inputValues,
      ["mobile"]: phoneNumber.slice(number),
      ["pre_mobile"]: "+" + countryCode,
    });
    const updatedErrors = validateValues({
      ...inputValues,
      mobile: phoneNumber.slice(number),
    });

    setErrors(updatedErrors);
  };

  return (
    <>
      <Seo SeoData={SeoheadData.gameLandingPageSeoData}></Seo>
      <ToastContainer />
      {/* header */}
      <div className="header d-flex game-heder">
        <Navbar expand="lg" className="w-100">
          <Container className="align-items-center h-100">
            <Link to="/" className="logo">
              <img
                className="logo"
                src="../../assets/img/new-logo.svg"
                alt="vasu-LOGO"
              />
            </Link>
            <div className="d-flex align-items-center head pe-sm-2 pe-0">
              <div className="d-flex align-items-center me-lg-4 me-sm-2 me-1">
                <Link to="tel:+917359349940" className="head-circle">
                  <IoCallSharp />
                </Link>
                <Link to="tel:+917359349940">
                  <p className="d-lg-block d-none">(+91) 7359349940</p>
                </Link>
              </div>
              <div className="d-flex align-items-center">
                <Link to="mailto:info@vasundhara.io" className="head-circle">
                  <IoMailSharp />
                </Link>
                <Link to="mailto:info@vasundhara.io" className="text-lowercase">
                  <p className="d-lg-block d-none">info@vasundhara.io</p>
                </Link>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>

      <div className="game-hero">
        <video
          className="zombie-video"
          controls={false}
          autoPlay
          webkit-playsInline
          muted
          playsInline
          preload="yes"
          type="video/mp4"
          loop
          width="100%"
        >
          <source src={"./assets/zombie.mp4"} type="video/mp4" />
        </video>

        <Container className="game-banner">
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="game-hero-content ">
                <h1>
                  The Future of Game Development is Here!
                  <br />
                  Build The Game of Your Dreams with Vasundhara
                </h1>
                {/* <Collapse in={open} className="game-hero-collapse">
                                    <div id="example-collapse-text">
                                        <h5>
                                            Your Ultimate Stop For Game Development <br />
                                            India’s #1 Game Developers
                                        </h5>
                                        <h5>
                                            The Ultimate Stop For Captivating Games
                                        </h5>
                                    </div>
                                </Collapse>
                                <Button
                                    className="hero-btn"
                                    onClick={() => setOpen(!open)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open}
                                >
                                    {open ? `Show Less` : `Read More`}
                                </Button> */}
              </div>
            </Col>

            <Col lg={6}>
              <div className="card-form">
                {/* <div id="zf_div_fpZNc_jpxJ1iDX86wOWO1jFN5GbJ8mctI0yfhAVRpFE"></div> */}

                <div className="contact-form position-relative">
                {loading && (
                        <div className="loader_form d-flex align-items-center justify-content-center">
                          <img src={Loader} />
                        </div>
                )}
                    <div className="quote-form bg-white p-0 mt-4 game-landing-form px-3">
                      <div className="d-flex align-items-center justify-content-between quote-input-main ">
                        <label>
                          Name <span>*</span>
                        </label>
                        <div className="input-main d-flex align-items-center gap-3">
                          <div className="w-100">
                            <input
                              type="text"
                              className="w-100"
                              placeholder="First Name"
                              name="first_name"
                              value={inputValues.first_name}
                              onChange={onchange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                            />
                            {errors.first_name && (
                              <p className="error">{errors.first_name}</p>
                            )}
                          </div>
                          <div className="w-100">
                            <input
                              type="text"
                              className="w-100"
                              placeholder="Last Name"
                              name="last_name"
                              value={inputValues.last_name}
                              onChange={onchange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                            />
                            {errors.last_name && (
                              <p className="error">{errors.last_name}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                        <label>
                          Email <span>*</span>
                        </label>
                        <div className="input-main ">
                          <input
                            type="text"
                            className="w-100"
                            placeholder="Enter Your Valid Email"
                            value={inputValues.email}
                            name="email"
                            onChange={onchange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                          />
                          {errors.email && (
                            <p className="error">{errors.email}</p>
                          )}
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                        <label>Country</label>
                        <div className="input-main ">
                          <select
                            className="w-100"
                            name="country"
                            value={inputValues.country}
                            onChange={onchange}
                          >
                            <option value="">-Select-</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>UK</option>
                            <option>UAE</option>
                            <option>Canada</option>
                          </select>
                          {errors.country && (
                            <p className="error">{errors.country}</p>
                          )}
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                        <label>
                          Contact Number<span>*</span>
                        </label>
                        <div className="input-main">
                          <div className=" d-flex align-items-center gap-3">
                            <PhoneInput
                              country={"in"}
                              enableSearch={true}
                              // value={inputValues.mobile}
                              value={inputValues.pre_mobile.concat(
                                inputValues.mobile
                              )}
                              onChange={handlePhoneNumberChange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                            />
                          </div>
                          {errors.mobile && (
                            <p className="error">{errors.mobile}</p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between quote-input-main mt-4 mt-sm-4">
                        <label>Message</label>
                        <div className="input-main d-flex align-items-center gap-3">
                          <textarea
                            className="w-100"
                            placeholder="Please Share Your Requirement"
                            rows={4}
                            name="description"
                            value={inputValues.description}
                            onChange={onchange}
                          />
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-center mt-5 mb-4">
                        <Button
                          className="submit-form-cos"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <UpworkCard />

      <div className="benefit-main">
        <Container>
          <div className="heading" data-aos="zoom-in">
            <h2>What Makes Us the India’s Best Game Developers?</h2>
          </div>

          <div className="benefit-develop pt-xl-5 pt-0 d-flex justify-content-between">
            <div className="benefit-box" data-aos="zoom-in">
              <div className="circled">
                <div className="small-circled"></div>
                <div className="sub-circled">
                  <img
                    className="img-fluid"
                    src="../assets/img/game_landing/game-benefit/Diverse-Skill-Set.svg"
                    alt="Diverse-skill-set"
                  />
                </div>
              </div>
              <div className="benefit-content">
                <h5>Diverse Skill Set</h5>
                <p>
                  Our game developers have expertise in 2D, 3D, Unity, AR, VR &
                  other interactive genres supported on iOS & Android.
                </p>
              </div>
            </div>

            <div className="benefit-box" data-aos="zoom-in">
              <div className="circled">
                <div className="small-circled"></div>
                <div className="sub-circled">
                  <img
                    className="img-fluid"
                    src="../assets/img/game_landing/game-benefit/Cost-Effective.svg"
                    alt="Cost-Effective"
                  />
                </div>
              </div>

              <div className="benefit-content">
                <h5>Cost Effective</h5>
                <p>
                  Vasundhara Game Development Company provides quality services
                  at an affordable price.
                </p>
              </div>
            </div>

            <div className="benefit-box" data-aos="zoom-in">
              <div className="circled">
                <div className="small-circled"></div>
                <div className="sub-circled">
                  <img
                    className="img-fluid"
                    src="../assets/img/game_landing/game-benefit/Quality-Performance.svg"
                    alt="Quality-Performance"
                  />
                </div>
              </div>
              <div className="benefit-content">
                <h5>Quality Performance</h5>
                <p>
                  Multi-platform support, top-notch features, and perfect coding
                  make our products stand out and assure quality performance.
                </p>
              </div>
            </div>

            <div className="benefit-box" data-aos="zoom-in">
              <div className="circled">
                <div className="small-circled"></div>
                <div className="sub-circled">
                  <img
                    className="img-fluid"
                    src="../assets/img/game_landing/game-benefit/Better-Profitability.svg"
                    alt="Better-Profitability"
                  />
                </div>
              </div>
              <div className="benefit-content">
                <h5>Better Profitability</h5>
                <p>
                  Our reliable team of experts is seasoned in developing and
                  testing games ensuring you get the best out of your returns.
                </p>
              </div>
            </div>

            <div className="benefit-box" data-aos="zoom-in">
              <div className="circled">
                <div className="small-circled"></div>
                <div className="sub-circled">
                  <img
                    className="img-fluid"
                    src="../assets/img/game_landing/game-benefit/Timely-Project-Delivery.svg"
                    alt="Timely-Project-Delivery"
                  />
                </div>
              </div>
              <div className="benefit-content">
                <h5>Prompt Delivery</h5>
                <p>
                  Equipped with best-in-class technology and team, we ensure
                  your project is delivered in time, designed & tested.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="how-it-work">
        <Container>
          <div className="heading" data-aos="zoom-in">
            <h2>How it Works</h2>
          </div>

          <Row className="pt-lg-5 pt-4 how-column">
            <Col md={6} data-aos="fade-right" className="mb-5 color-aqua">
              <div className="col-main-box">
                <div className="col-circle">
                  <div className="sub-col-circle">
                    <img
                      className="img-fluid"
                      src="../assets/img/game_landing/How-it-work/requirements.svg"
                      alt="Understanding-requirements"
                    />
                  </div>
                  <div className="sketcher"></div>
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center mb-5 color-aqua">
              <div className="col-main-content" data-aos="fade-left">
                <h5>Understanding Requirements</h5>
                <p>
                  Our team of developers understands your idea of the project
                  and needs and gathers all information.
                </p>
              </div>
            </Col>

            <Col md={6} data-aos="fade-right" className="mb-5 color-green">
              <div className="col-main-box">
                <div className="col-circle">
                  <div className="sub-col-circle">
                    <img
                      className="img-fluid"
                      src="../assets/img/game_landing/How-it-work/right-technology.svg"
                      alt="Using-right-technology"
                    />
                  </div>
                  <div className="sketcher"></div>
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center mb-5 color-green">
              <div className="col-main-content" data-aos="fade-left">
                <h5>Using Right Technology</h5>
                <p>
                  Using precise, robust, and the right user-friendly code, we
                  develop games that are interactive and stable.
                </p>
              </div>
            </Col>

            <Col md={6} data-aos="fade-right" className="mb-5 color-pink">
              <div className="col-main-box">
                <div className="col-circle">
                  <div className="sub-col-circle">
                    <img
                      className="img-fluid"
                      src="../assets/img/game_landing/How-it-work/working-on-design.svg"
                      alt="working-on-design"
                    />
                  </div>
                  <div className="sketcher"></div>
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center mb-5 color-pink">
              <div className="col-main-content" data-aos="fade-left">
                <h5>Designing</h5>
                <p>
                  At this step, your game is designed using the right technology
                  and best-suited themes and graphics.
                </p>
              </div>
            </Col>

            <Col md={6} data-aos="fade-right" className="mb-5 color-yellow">
              <div className="col-main-box">
                <div className="col-circle">
                  <div className="sub-col-circle">
                    <img
                      className="img-fluid"
                      src="../assets/img/game_landing/How-it-work/game-testing.svg"
                      alt="game-testing"
                    />
                  </div>
                  <div className="sketcher"></div>
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center mb-5 color-yellow">
              <div className="col-main-content" data-aos="fade-left">
                <h5>Testing</h5>
                <p>
                  Our quality assurance and testing team works agilely with our
                  development team to ensure all root problems and bugs are
                  eliminated.
                </p>
              </div>
            </Col>

            <Col md={6} data-aos="fade-right" className="mb-5 color-darkred">
              <div className="col-main-box">
                <div className="col-circle">
                  <div className="sub-col-circle">
                    <img
                      className="img-fluid"
                      src="../assets/img/game_landing/How-it-work/launching-the-game.svg"
                      alt="launching-the-game"
                    />
                  </div>
                  <div className="sketcher"></div>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center mb-md-5 mb-0 color-darkred"
            >
              <div className="col-main-content" data-aos="fade-left">
                <h5>Launching</h5>
                <p>
                  After thorough testing, the game is delivered on time and is
                  ready to launch.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Gamelanding-newgamedev">
        <Newgamedev title="Categories" />
      </div>

      <div className="our-gallery portfolio">
        <Container>
          <div className="heading our" data-aos="zoom-in">
            <h2>Our Gallery</h2>
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="portfolio-btns mt-5 mb-4 pb-3 ps-0 aos-init aos-animate justify-content-center"
            data-aos="zoom-in"
          >
            {comp.map((_data, i) => {
              return (
                <Tab eventKey={_data.tag} title={_data.title} key={i}>
                  <Row className="mt-sm-5 mt-4 align-items-center">
                    <Col xl={6}>
                      <img
                        className="img-fluid d-block m-auto"
                        style={{ borderRadius: "14px" }}
                        data-aos="zoom-in"
                        src={_data.medias && _data.medias[0].image_path}
                        alt={_data.tag}
                      />
                    </Col>
                    <Col xl={6} className="mt-sm-5 mt-4 mt-xl-0">
                      <div className="mountain-content main-game-hero">
                        <div className="heading" data-aos="zoom-in">
                          <h2 className="text-start">{_data.title}</h2>
                        </div>
                        <p>{_data.landing_description}</p>

                        <Row className="my-sm-4 mt-3 pt-2 max-row">
                          <Col xxl={4} sm={4} className="col-6 mb-sm-0 mb-3">
                            <div className="game-k">
                              <img
                                className="img-fluid"
                                src="../assets/img/game_landing/game-hero/affection.svg"
                                alt="affection"
                              />
                              <h6>{_data.active_users}</h6>
                              <p>Happy User</p>
                            </div>
                          </Col>
                          <Col xxl={4} sm={4} className="col-6 mb-sm-0 mb-3">
                            <div className="game-k">
                              <img
                                className="img-fluid"
                                src="../assets/img/game_landing/game-hero/app-installation.svg"
                                alt="app-installation"
                              />
                              <h6>{_data.total_install}</h6>
                              <p>App Installed</p>
                            </div>
                          </Col>
                          <Col xxl={4} sm={4} className="col-6">
                            <div className="game-k">
                              <img
                                className="img-fluid"
                                src="../assets/img/game_landing/game-hero/positive-review.svg"
                                alt="positive-review"
                              />
                              <h6>{_data.total_reviews}</h6>
                              <p>Positive Reviews</p>
                            </div>
                          </Col>
                        </Row>

                        <div className="d-flex mt-sm-4 mt-3 justify-content-between max-link">
                          <div className="tab-r">
                            <a href={_data.google_store_url} target="_blank">
                              <img
                                className="img-fluid"
                                src="../assets/img/game_landing/game-hero/googleplay.svg"
                                alt="googleplay"
                              />
                            </a>
                          </div>
                          <div className="tab-r">
                            <a href={_data.apple_store_url} target="_blank">
                              <img
                                className="img-fluid"
                                src="../assets/img/game_landing/game-hero/appstore.svg"
                                alt="appstore"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab>
              );
            })}
          </Tabs>
        </Container>
      </div>

      <div className="bg-blue-light pop-padding margin-2">
        <PortfolioCom
          isViewBtn={false}
          heading="Technologies"
          isTabOn={true}
          viewurl={"portfolios"}
          tabData={tabName}
          activeTab="Backend"
          learnbutton={false}
        />
      </div>

      <div className="game-review">
        <Container>
          <div className="review-box">
            <div className="heading" data-aos="zoom-in">
              <h2>Here’s what our customers love about us</h2>
            </div>
            <Slider {...allData.settings2}>
              {testimonials.map((_testi, i) => {
                return (
                  <div key={i}>
                    <p data-aos="zoom-in">{_testi.description}</p>
                    <h5 data-aos="zoom-in">{_testi.name}</h5>
                  </div>
                );
              })}

              {/* <div>
                                <p data-aos="zoom-in">“On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will”</p>
                                <h5 data-aos="zoom-in">John Doe</h5>
                            </div>
                            <div>
                                <p data-aos="zoom-in">“On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will”</p>
                                <h5 data-aos="zoom-in">John Doe</h5>
                            </div>
                            <div>
                                <p data-aos="zoom-in">“On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will”</p>
                                <h5 data-aos="zoom-in">John Doe</h5>
                            </div> */}
            </Slider>
          </div>

          {/* <div className="review-box">
                        <div className="heading" data-aos="zoom-in">
                            <h2>Reviews</h2>
                        </div>
                        {
                            testimonials.map((_testi, i) => {
                                return (
                                    <div key={i}>
                                        <p data-aos="zoom-in">{_testi.description}</p>
                                        <h5 data-aos="zoom-in">{_testi.name}</h5>
                                    </div>
                                )
                            })

                        }
                    </div> */}
        </Container>
      </div>

      <Othersevice
        head="Looking for Other Services?"
        otherData={OtherGameData}
      />

      <Faq
        title="Answers to all your queries delivered right here."
        faqData={gameLandingFaqData}
        buttnText="view all"
      />

      {/* footer */}
      <section className="position-relative footer-mt game-footer">
        <div className="footer footer-content ">
          <div className="container lets-up-footer">
            <div className="lets-box">
              <Row className="align-items-center">
                <Col lg={8}>
                  <div className="lets-left-box">
                    <h5>What can we help you develop?</h5>
                    <h2>Let’s Build Your Dream Game, Together!</h2>
                  </div>
                </Col>
                <Col lg={4} className="mt-lg-0 mt-3">
                  <div className="lets-right-box text-lg-end text-center mt-lg-0 mt-3">
                    <button className="lets-btn" onClick={handleClick}>
                      Get started
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="on-social border-0 mt-20-m">
            <div className="container">
              <div className="row align-items-center justify-content-center ">
                <Col lg={12} className="">
                  <div className="social-icon d-flex justify-content-center">
                    <p className="mb-0 md-text-left text-center">
                      Copyright @2022 Vasundhara Infotech All Right Reserved.
                    </p>
                  </div>
                </Col>

                <Col lg={12} className="mt-4">
                  <div className="social-icon d-flex align-items-center justify-content-center">
                    <a href={footerAddress.linked_in} target="_blank">
                      <img src="../../assets/img/Footer/link.png" alt="link" />
                    </a>
                    <a href={footerAddress.youtube} target="_blank">
                      <img
                        src="../../assets/img/Footer/Vector4.png"
                        alt="Vector4"
                      />
                    </a>
                    <a href={footerAddress.behance} target="_blank">
                      <img
                        src="../../assets/img/Footer/Vector3.png"
                        alt="Vector3"
                      />
                    </a>
                    <a href={footerAddress.facebook} target="_blank">
                      <img
                        src="../../assets/img/Footer/Vector2.png"
                        alt="Vector2"
                      />
                    </a>
                    <a href={footerAddress.twitter} target="_blank">
                      <img
                        src="../../assets/img/Footer/Vector1.png"
                        alt="Vector1"
                      />
                    </a>
                    <a href={footerAddress.skype} target="_blank">
                      <img
                        src="../../assets/img/Footer/Vector.png"
                        alt="Vector"
                      />
                    </a>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gamelanding;
